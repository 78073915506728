import '../../App.scss';
import { ethers } from "ethers";
import React, { useEffect, useState, useRef } from "react";
import erc20ContractABI from '../../abi/erc20.json';
import slotmachineContractABI from '../../abi/slotmachine.json';
import useWebSocket from 'react-use-websocket';
import ReCAPTCHA from "react-google-recaptcha";
import { fetchConfigurationFromBackend, authWithBackend } from '../../store/slices/config.slice';
import { useSelector, useDispatch } from 'react-redux';
import { checkIsMetamaskInstalled, isNetworkEquals, updateAddress, updateNetworkVersion } from '../../store/slices/etherium.slice';
import { handleEvent, setWebsocketConnectionIsActive } from '../../store/slices/events.slice';
import Slotmachine from '../../components/Slotmachine/Slotmachine';
import WinnersTable from '../../components/Winners/Winners';
import { refreshCurrentDeposit } from '../../store/slices/playtoken.slice';
import { BACKEND_API, AUTH_HEARTBEAT_EACH_SECONDS } from '../../settings';
import coin from '../../assets/coin-1.png';
import coin2 from '../../assets/coin-2.png';
import coin3 from '../../assets/coin-3.png';
import coin4 from '../../assets/coin-4.png';
import '../../scss/app.scss'
import { useSearchParams } from 'react-router-dom';
import { saveQueryParams } from '../../store/query/query_params';


const App = () => {
    const dispatch = useDispatch();
    const backendConfig = useSelector((state) => state.configuration.config);
    const accessToken = useSelector((state) => state.configuration.accessToken);
    const currentAccount = useSelector((state) => state.etherium.currentAccount);
    const etheriumConfiguration = useSelector((state) => state.etherium);

    const [socketUrl, setSocketUrl] = useState(null);
    const recaptchaRef = useRef();
    const [erc20PaytokenContract, setERC20PaytokenContract] = useState(null);
    const [slotMachineContract, setSlotMachineContract] = useState(null);
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl,
        {
            onOpen: () => {
                console.log('open ws connection');
                dispatch(setWebsocketConnectionIsActive(true));
                subscribeToPersonalRollEvents();
            },
            //Will attempt to reconnect on all close events, such as server shutting down
            shouldReconnect: (closeEvent) => {
                console.log('ws connection closed');
                dispatch(setWebsocketConnectionIsActive(false));
                return true;
            },
            reconnectAttempts: 10,
            reconnectInterval: 3000,
        }
    );

    const [searchParams, setSearchParams] = useSearchParams();

    function saveQueryOnce() {
        saveQueryParams(searchParams, setSearchParams)
    }

    async function checkNetwork() {
        const hexToDecimal = hex => parseInt(hex, 16);

        if (window.ethereum) {
            const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            console.log('chainId: ', hexToDecimal(chainId));
            dispatch(updateNetworkVersion(hexToDecimal(chainId)));

            window.ethereum.on("accountsChanged", () => {
                // Update current account and recreate smartcontracts
                connectToProvider();
            });
            window.ethereum.on('chainChanged', function (networkId) {
                const networkIdInt = hexToDecimal(networkId);

                console.log('chainChanged ', networkIdInt);

                dispatch(updateNetworkVersion(networkIdInt));
            });
        }
    }

    useEffect(() => {
        checkNetwork();
    }, [dispatch]);

    // Fetch config first
    useEffect(() => {
        saveQueryOnce()

        dispatch(fetchConfigurationFromBackend);
        dispatch(checkIsMetamaskInstalled);
        // TODO Maybe use accessToken through query param to connect
        setSocketUrl(BACKEND_API.replace('http', 'ws') + "ws/v1/events");
    }, [dispatch]);

    useEffect(() => {
        dispatch(authWithBackend(recaptchaRef));
    }, [recaptchaRef])

    // Request current account and signer
    useEffect(() => {
        if (!etheriumConfiguration.isMetamaskInstalled) {
            return;
        }
        // swithNetwork();
        connectToProvider();
    }, [etheriumConfiguration]);

    useEffect(() => {
        if (lastMessage !== null) {
            const wsEvent = JSON.parse(lastMessage.data);
            dispatch(handleEvent(wsEvent, recaptchaRef));
        }
    }, [lastMessage, recaptchaRef]);

    function subscribeToPersonalRollEvents() {
        if (!currentAccount) {
            return
        }
        // Subscribe on roll events for current account
        const event = {
            "name": "ROLL_EVENTS_SUBSCRIBE",
            "data": currentAccount,
        }
        if (!!sendMessage) {
            sendMessage(JSON.stringify(event));
        }
    }

    useEffect(() => {
        subscribeToPersonalRollEvents();
    }, [currentAccount]);

    useEffect(() => {
        let refreshAuthWithWebSocket = setInterval(() => {
            if (!accessToken) {
                return
            }

            const event = {
                "name": "AUTH_HEARTBEAT",
                "data": accessToken,
            }
            if (!!sendMessage) {
                sendMessage(JSON.stringify(event));
            }
        }, AUTH_HEARTBEAT_EACH_SECONDS * 1000)
        return () => {
            clearInterval(refreshAuthWithWebSocket);
        }
    }, [accessToken]);

    const connectToProvider = async () => {
        if (!isNetworkEquals(backendConfig, etheriumConfiguration)) {
            return
        }
        if (!backendConfig.slotmachine_address || !backendConfig.paytoken_address) {
            return
        }

        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();

        const slotmachineContract = new ethers.Contract(backendConfig.slotmachine_address, slotmachineContractABI, signer);
        const erc20 = new ethers.Contract(backendConfig.paytoken_address, erc20ContractABI, signer);
        // erc20.on('Transfer', (from, to, amount, event) => {
        //   console.log({from, to, amount, event});
        // })

        dispatch(refreshCurrentDeposit(erc20));
        setERC20PaytokenContract(erc20);
        setSlotMachineContract(slotmachineContract);
        dispatch(updateAddress(address));
    }

    function renderReCaptha() {
        if (!backendConfig || !backendConfig.captha_site_key) {
            return;
        }

        if (backendConfig.captha_site_key === "fake") {
            return;
        }
        return (
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={backendConfig.captha_site_key}
            />
        )
    }



    return (
        <div className="App">
            {renderReCaptha()}
            <div className="title-block">
                <h1 className="main-title">PLAY & WIN <span style={{ color: '#00FEDF' }}>MONEY</span></h1>
            </div>
            <div className='flex-wrap'>
                <WinnersTable />
                <Slotmachine
                    recaptchaRef={recaptchaRef}
                    slotMachineContract={slotMachineContract}
                    erc20PaytokenContract={erc20PaytokenContract}
                />
            </div>
            <img src={coin} alt="" className='cir-1' />
            <img src={coin2} alt="" className='cir-2' />
            <img src={coin3} alt="" className='cir-3' />
            <img src={coin4} alt="" className='cir-4' />
        </div>
    );
};

export default App;
