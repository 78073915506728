import { useSelector, useDispatch } from 'react-redux';
import { playerSetDeposit } from '../../store/slices/playtoken.slice';
import { roll, setMultiplier } from '../../store/slices/events.slice';
import { useEffect, useState } from 'react';
import GlowButton from "../UI/btns/GlowButton/GlowButton";
import CommonButton from "../UI/btns/CommonButton/CommonButton";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Network from '../UI/Network/Network';
import {isNetworkEquals} from '../../store/slices/etherium.slice';

const WinCombinations = ({ result }) => {
    const multiplier = useSelector((state) => state.events.multiplier);

    const TRIPPLE_BAR = 19;
    const SEVEN = 20;
    const CHERRY = 1;
    const DIAMOND = 2;
    const DOUBLE_BAR = 4;
    const ONE_BAR = 5;

    function _allThree(result, comparator) {
        return result.Slot1 == comparator && result.Slot2 == comparator && result.Slot3 == comparator;
    }

    function _anyTwo(result, comparator) {
        const check1 = result.Slot1 == comparator && result.Slot2 == comparator;
        const check2 = result.Slot1 == comparator && result.Slot3 == comparator;
        const check3 = result.Slot2 == comparator && result.Slot3 == comparator;
        return check1 || check2 || check3;
    }

    function _anyOne(result, comparator) {
        return result.Slot1 == comparator || result.Slot2 == comparator || result.Slot3 == comparator;
    }

    // Solidity contract function
    // function checkCombination(RollResult memory result) internal pure returns(uint256) {
    //     if (_allThree(result, DIAMOND)) {
    //         return 1600;
    //     }
    //     if (_allThree(result, SEVEN)) {
    //         return 100;
    //     }
    //     if (_allThree(result, TRIPPLE_BAR)) {
    //         return 30;
    //     }
    //     if (_allThree(result, DOUBLE_BAR)) {
    //         return 20;
    //     }
    //     if (_allThree(result, CHERRY) || _allThree(result, ONE_BAR)) {
    //         return 10;
    //     }
    //     if (_anyTwo(result, CHERRY) || _anyTwo(result, DOUBLE_BAR)) {
    //         return 5;
    //     }
    //     if (_anyOne(result, CHERRY)) {
    //         return 2;
    //     }
    //     return 0;
    // }

    const withMultiplier = (value) => {
        return value * multiplier;
    }

    return (
        <div className='win-combinations'>
            <table>
                <tbody>
                    <tr>
                        <th>Combination</th>
                        <th>Description</th>
                        <th>Multiplier</th>
                        <th>Win AMount</th>
                    </tr>
                    <tr className={_allThree(result, DIAMOND) ? 'current-win' : ''}>
                        <td>
                            <img src={`./images/reels/png-format/${DIAMOND}.png`} alt="" />
                            <img src={`./images/reels/png-format/${DIAMOND}.png`} alt="" />
                            <img src={`./images/reels/png-format/${DIAMOND}.png`} alt="" />
                        </td>
                        <td>All three</td>
                        <td>x{multiplier}</td>
                        <td>{withMultiplier(1600)} USDT</td>
                    </tr>
                    <tr className={_allThree(result, SEVEN) ? 'current-win' : ''}>
                        <td>
                            <img src={`./images/reels/png-format/${SEVEN}.png`} alt="" />
                            <img src={`./images/reels/png-format/${SEVEN}.png`} alt="" />
                            <img src={`./images/reels/png-format/${SEVEN}.png`} alt="" />
                        </td>
                        <td>All three</td>
                        <td>x{multiplier}</td>
                        <td>{withMultiplier(100)} USDT</td>
                    </tr>
                    <tr className={_allThree(result, TRIPPLE_BAR) ? 'current-win' : ''}>
                        <td>
                            <img src={`./images/reels/png-format/${TRIPPLE_BAR}.png`} alt="" />
                            <img src={`./images/reels/png-format/${TRIPPLE_BAR}.png`} alt="" />
                            <img src={`./images/reels/png-format/${TRIPPLE_BAR}.png`} alt="" />
                        </td>
                        <td>All three</td>
                        <td>x{multiplier}</td>
                        <td>{withMultiplier(30)} USDT</td>
                    </tr>
                    <tr className={_allThree(result, DOUBLE_BAR) ? 'current-win' : ''}>
                        <td>
                            <img src={`./images/reels/png-format/${DOUBLE_BAR}.png`} alt="" />
                            <img src={`./images/reels/png-format/${DOUBLE_BAR}.png`} alt="" />
                            <img src={`./images/reels/png-format/${DOUBLE_BAR}.png`} alt="" />
                        </td>
                        <td>All three</td>
                        <td>x{multiplier}</td>
                        <td>{withMultiplier(20)} USDT</td>
                    </tr>
                    <tr className={_allThree(result, CHERRY) ? 'current-win' : ''}>
                        <td>
                            <img src={`./images/reels/png-format/${CHERRY}.png`} alt="" />
                            <img src={`./images/reels/png-format/${CHERRY}.png`} alt="" />
                            <img src={`./images/reels/png-format/${CHERRY}.png`} alt="" />
                        </td>
                        <td>All three</td>
                        <td>x{multiplier}</td>
                        <td>{withMultiplier(10)} USDT</td>
                    </tr>
                    <tr className={_allThree(result, ONE_BAR) ? 'current-win' : ''}>
                        <td>
                            <img src={`./images/reels/png-format/${ONE_BAR}.png`} alt="" />
                            <img src={`./images/reels/png-format/${ONE_BAR}.png`} alt="" />
                            <img src={`./images/reels/png-format/${ONE_BAR}.png`} alt="" />
                        </td>
                        <td>All three</td>
                        <td>x{multiplier}</td>
                        <td>{withMultiplier(10)} USDT</td>
                    </tr>
                    <tr className={_anyTwo(result, CHERRY) && !_allThree(result, CHERRY) ? 'current-win' : ''}>
                        <td>
                            <img src={`./images/reels/png-format/${CHERRY}.png`} alt="" />
                            <img src={`./images/reels/png-format/${CHERRY}.png`} alt="" />
                        </td>
                        <td>Any two</td>
                        <td>x{multiplier}</td>
                        <td>{withMultiplier(5)} USDT</td>
                    </tr>
                    <tr className={_anyTwo(result, DOUBLE_BAR) && !_allThree(result, DOUBLE_BAR) ? 'current-win' : ''}>
                        <td>
                            <img src={`./images/reels/png-format/${DOUBLE_BAR}.png`} alt="" />
                            <img src={`./images/reels/png-format/${DOUBLE_BAR}.png`} alt="" />
                        </td>
                        <td>Any two</td>
                        <td>x{multiplier}</td>
                        <td>{withMultiplier(5)} USDT</td>
                    </tr>
                    <tr className={_anyOne(result, CHERRY) && !_anyTwo(result, CHERRY) && !_allThree(result, CHERRY) ? 'current-win' : ''}>
                        <td>
                            <img src={`./images/reels/png-format/${CHERRY}.png`} alt="" />
                        </td>
                        <td>Any one</td>
                        <td>x{multiplier}</td>
                        <td>{withMultiplier(2)} USDT</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}


const RollResult = ({ rollResult, renderRoll }) => {
    if (rollResult == null) {
        rollResult = {};
    }

    return (
        <div className='slotmachine'>
            <div className='reels'>
                <div className='slotmachine-container'>
                    <div id="slot-line" className="spinner-container">
                        <Spinner slot={rollResult.Slot1} />
                        <Spinner slot={rollResult.Slot2} />
                        <Spinner slot={rollResult.Slot3} />
                    </div>
                    {renderRoll()}
                </div>
                <WinCombinations result={rollResult} />
            </div>
        </div>
    );
};

const Spinner = ({ slot }) => {
    const [position, setPosition] = useState(0);
    const [transitionDuration, setTransitionDuration] = useState(1000);


    useEffect(() => {
        if (!slot) {
            let timer = setInterval(() => {
                setPosition((prevPosition) => {
                    if (prevPosition > 4000) {
                        setTransitionDuration(5 * 1000);
                        return 0;
                    }
                    setTransitionDuration(randNumber(5) * 1000);
                    return prevPosition + 200;
                });
            }, transitionDuration)
            return () => {
                clearInterval(timer);
            };
        }
        let newPosition = (slot * 100) - 200;
        if (newPosition < 0) {
            newPosition = 1900
        }
        setPosition(newPosition);
    }, [slot]);

    const randNumber = (to) => {
        return Math.floor(Math.random() * to) + 1
    }

    return (
        <div style={{
            backgroundPosition: '0px -' + position + 'px',
            color: 'red',
            transitionDuration: (transitionDuration / 1000) + 's',
            transitionTimingFunction: !slot ? 'linear' : 'ease-in-out',
        }} className={`icons-sprite`}>
        </div>
    )
}

const Slotmachine = ({
    recaptchaRef,
    slotMachineContract,
    erc20PaytokenContract,
}) => {
    const dispatch = useDispatch();
    const backendConfig = useSelector((state) => state.configuration.config);
    const etheriumConfiguration = useSelector((state) => state.etherium);
    const latestRollResult = useSelector((state) => state.events.latestRollResult);
    const spinning = useSelector((state) => state.events.spinning);
    const currentDeposit = useSelector((state) => state.playtoken.deposit);
    const multiplier = useSelector((state) => state.events.multiplier);
    const wsConnectionActive = useSelector((state) => state.events.wsConnectionActive);

    async function rollReels() {
        dispatch(roll(recaptchaRef, slotMachineContract, erc20PaytokenContract))
    }

    function allowTokens() {
        dispatch(playerSetDeposit(erc20PaytokenContract, 10));
    }

    async function removeDeposit() {
        dispatch(playerSetDeposit(erc20PaytokenContract, 0));
    }

    function renderRollButton() {
        if (spinning) {
            return <GlowButton disabled={true}>Waiting...</GlowButton>
        }
        if (!currentDeposit || currentDeposit === '0') {
            return <GlowButton disabled={true} title={'Please increase deposit to Roll'}>Roll!</GlowButton>
        }
        return <GlowButton onClick={rollReels}>Roll!</GlowButton>
    }

    function renderButtons() {
        if (wsConnectionActive) {
            return (
                <div className='action-buttons'>
                    {renderRollButton()}
                    <CommonButton onClick={allowTokens} title={'You can deposit 0 to remove allowance'}>
                        Deposit
                    </CommonButton>
                </div>
            )
        }
        return (<h3>Lost connection. Waiting reconnect</h3>)
    }

    function renderRoll() {
        if (!isNetworkEquals(backendConfig, etheriumConfiguration)) {
            return <Network
                backendConfig={backendConfig}
                etheriumConfiguration={etheriumConfiguration}
            />
        }
        return (
            <div style={{ marginTop: '30px' }}>
                <div className="title">
                    <p>Multiplier(Bet): x{multiplier} USDT</p>
                    <div style={{ margin: '20px 0' }}>
                        <RangeSlider
                            onInput={(e) => dispatch(setMultiplier(e[1]))}
                            onRangeDragEnd={(e) => console.log(e)}
                            onChange={(e) => console.log(e)}
                            className="single-thumb"
                            thumbsDisabled={[true, false]}
                            rangeSlideDisabled={true}
                            min={1}
                            max={20}
                            value={[0, multiplier]}
                        />
                    </div>
                </div>
                <h3 className='main-deposit'>Current deposit: {currentDeposit} USDT</h3>
                {renderButtons()}
            </div>
        )
    }

    if (!backendConfig) {
        return (<div></div>)
    }

    return (
        <div>
            <RollResult
                rollResult={latestRollResult}
                spinning={spinning}
                decimals={backendConfig.paytoken_decimals}
                renderRoll={renderRoll}
            />
        </div>
    );
};

export default Slotmachine;
