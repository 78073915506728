import React from 'react';
import check from "../../../assets/svg/check.svg";
import classes from './Network.module.scss';
import { switchNetwork, isNetworkEquals } from '../../../store/slices/etherium.slice';
import { useDispatch } from 'react-redux';
import CommonButton from '../btns/CommonButton/CommonButton';


export const Network = ({backendConfig, etheriumConfiguration}) => {
    const dispatch = useDispatch();

    if (!isNetworkEquals(backendConfig, etheriumConfiguration)) {
        return (<CommonButton onClick={() => {dispatch(switchNetwork)}}>Switch network</CommonButton>)
    }

    return null;
};

export const NetworkOk = ({backendConfig, etheriumConfiguration}) => {
    if (!isNetworkEquals(backendConfig, etheriumConfiguration)) {
        return null;
    }

    return (<div className={classes.Network}><img src={check} alt="" width={20}/>Network is fine</div>)
};

export default Network;