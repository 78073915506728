import React from 'react';
import classes from './GlowButton.module.scss';


const GlowButton = ({children, onClick, disabled = false, title}) => {
    return (
        <button className={classes.Button} onClick={onClick} disabled={disabled} title={title}>
            {children}
        </button>
    );
};

export default GlowButton;