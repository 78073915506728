
import { createSlice } from '@reduxjs/toolkit';
import { BACKEND_API } from '../../settings';

const initialState = {
    config: {},
    accessToken: null
};

const configurationSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfiguration: (state, { payload }) => {
            state.config = payload;
        },
        setAccessToken: (state, { payload }) => {
            state.accessToken = payload;
        },
    },
});

const { setConfiguration, setAccessToken } = configurationSlice.actions;

export async function fetchConfigurationFromBackend(dispatch, getState) {
    const response = await fetch(BACKEND_API + 'api/v1/config', { method: 'GET' });
    const backendConfig = await response.json();
    dispatch(setConfiguration(backendConfig))
}

export function saveAccessToken(token) {
    return function(dispatch, getState) {
        dispatch(setAccessToken(token))
    }
}

export function authWithBackend(recaptchaRef) {
    return async function (dispatch, getState) {
        try {
            const backendConfig = getState().configuration.config;
            const accessToken = await authRequest(recaptchaRef, backendConfig)
            dispatch(setAccessToken(accessToken))
        } catch (e) {
            console.log(e);
        }
    }
}

export async function authRequest(recaptchaRef, backendConfig) {
    if (!backendConfig.captha_site_key) {
        return "";
    }
    let capthaToken = "";
    if (backendConfig.captha_site_key === "fake" || backendConfig.captha_site_key == "") {
        capthaToken = "fake";
    } else {
        // Check if recaptchaRef is null?
        capthaToken = await recaptchaRef.current.executeAsync();
    }

    const response = await fetch(BACKEND_API + 'api/v1/auth', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            captha_token: capthaToken,
        })
    });
    const authResponse = await response.json();
    return authResponse.access_token;
}

export default configurationSlice.reducer;